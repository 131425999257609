// project imports
import { apiFetch } from './requests';

const DOMAIN = process.env.REACT_APP_API_URL;

const loginAPI = async ({ userName, password, refreshToken }) => {
    const url = refreshToken
        ? `${DOMAIN}auth/login?refreshToken=${encodeURIComponent(refreshToken)}`
        : `${DOMAIN}auth/login?userName=${encodeURIComponent(userName)}&password=${encodeURIComponent(password)}`;
    console.log(`Calling: ${url}`);
    const getResponse = await apiFetch({ url, auth: false });
    console.log(getResponse);
    return getResponse;
};

const loginChangePasswordAPI = async ({ session, userID, password }) => {
    const url = `${DOMAIN}auth/challenge/new-password?userID=${userID}&newPassword=${encodeURIComponent(password)}&session=${session}`;
    console.log(`Calling: ${url}`);
    const getResponse = await apiFetch({ url, auth: true });
    console.log(getResponse);
    return getResponse;
};

const registerUserAPI = async ({ email, password, givenName, familyName, profession }) => {
    const encodedPassword = encodeURIComponent(password);
    const url = `${DOMAIN}auth/register?email=${email}&password=${encodedPassword}&givenName=${givenName}&familyName=${familyName}&profession=${profession}`;
    console.log(`Calling: ${url}`);
    const getResponse = await apiFetch({ url, auth: false });
    console.log(getResponse);
    return getResponse;
};

const addToWaitListAPI = async ({ data }) => {
    const url = `${DOMAIN}waitlist`;
    console.log(`Calling: ${url}`);
    const body = JSON.stringify(data);
    const response = await apiFetch({ method: 'POST', url, body, auth: false });
    return response;
};

const signUpUserAPI = async ({ data }) => {
    const url = `${DOMAIN}auth/signup`;
    console.log(`Calling: ${url}`);
    const body = JSON.stringify(data);
    const response = await apiFetch({ method: 'POST', url, body, auth: false });
    return response;
};

const signUpAdminAPI = async ({ data }) => {
    const url = `${DOMAIN}/auth/admin/signup`;
    console.log(`Calling: ${url}`);
    const body = JSON.stringify(data);
    const response = await apiFetch({ method: 'POST', url, body, auth: false });
    return response;
};

const registerUserConfirmAPI = async ({ userName, confirmationCode }) => {
    const url = `${DOMAIN}auth/verify?userName=${encodeURIComponent(userName)}&confirmationCode=${confirmationCode}`;
    console.log(`Calling: ${url}`);
    const getResponse = await apiFetch({ url, auth: false });
    console.log(getResponse);
    return getResponse;
};

const registerResendCodeAPI = async ({ userName }) => {
    const url = `${DOMAIN}auth/resend?userName=${encodeURIComponent(userName)}`;
    console.log(`Calling: ${url}`);
    const getResponse = await apiFetch({ url, auth: false });
    console.log(getResponse);
    return getResponse;
};

const forgotPasswordAPI = async ({ userName }) => {
    const url = `${DOMAIN}auth/forgot?userName=${encodeURIComponent(userName)}`;
    console.log(`Calling: ${url}`);
    const getResponse = await apiFetch({ url, auth: false });
    console.log(getResponse);
    return getResponse;
};

const forgotPasswordConfirmAPI = async ({ userName, confirmationCode, password }) => {
    const encodedPassword = encodeURIComponent(password);
    const encodedUserName = encodeURIComponent(userName);
    const url = `${DOMAIN}auth/forgot/confirm?userName=${encodedUserName}&confirmationCode=${confirmationCode}&password=${encodedPassword}`;
    console.log(`Calling: ${url}`);
    const getResponse = await apiFetch({ url, auth: false });
    console.log(getResponse);
    return getResponse;
};

const getUserListAPI = async () => {
    const url = `${DOMAIN}auth/user/list`;
    const response = await apiFetch({ url });
    console.log(response);
    return response;
};

const lockUserAPI = async ({ id }) => {
    const url = `${DOMAIN}auth/user/lock?id=${id}`;
    const response = await apiFetch({ url });
    console.log(response);
    return response;
};

const deactivateAccountAPI = async () => {
    const url = `${DOMAIN}auth/deactivate`;
    const response = await apiFetch({ method: 'DELETE', url });
    return response;
};

const updateUserPasswordAPI = async ({ oldPassword, newPassword }) => {
    const url = `${DOMAIN}user/password?old=${encodeURIComponent(oldPassword)}&new=${encodeURIComponent(newPassword)}`;
    const response = await apiFetch({ method: 'PUT', url });
    return response;
};

const checkUserAPI = async ({ userName }) => {
    const url = `${DOMAIN}auth/checkuser?userName=${encodeURIComponent(userName)}`;
    const response = await apiFetch({ url, auth: false });
    return response;
};

const getFeeDataAPI = async ({ partner }) => {
    const url = `${DOMAIN}auth/getFeeData?partner=${partner}`;
    const response = await apiFetch({ url, auth: false });
    return response;
};

export {
    loginAPI,
    loginChangePasswordAPI,
    getUserListAPI,
    lockUserAPI,
    registerUserAPI,
    signUpUserAPI,
    registerUserConfirmAPI,
    registerResendCodeAPI,
    forgotPasswordAPI,
    forgotPasswordConfirmAPI,
    deactivateAccountAPI,
    updateUserPasswordAPI,
    signUpAdminAPI,
    addToWaitListAPI,
    checkUserAPI,
    getFeeDataAPI
};

const DOMAIN = process.env.REACT_APP_API_URL;

// Format Headers
const jsonHeader = ({ auth, mimeType }) => {
    const headers = new Headers();
    if (mimeType) {
        headers.append('Content-Type', mimeType);
    } else {
        headers.append('Content-Type', 'application/json');
    }

    // Get token from local storage if needed
    if (auth) {
        const token = localStorage.getItem('idToken');
        headers.append('Authorization', token);
    }
    return headers;
};

// Common error checking
const evaluateResponse = ({ success, jsonResponse }) => {
    if (success && jsonResponse?.success) {
        return jsonResponse.data;
    }

    // Format Error Message
    let message = '';
    if (jsonResponse?.errorMessage) {
        message = jsonResponse.errorMessage;
    } else if (jsonResponse?.errorType) {
        message = jsonResponse.errorType;
    } else {
        message = `An error has occured: ${JSON.stringify(jsonResponse)}`;
    }
    const responseError = new Error(message);

    // Include ErrorType
    if (jsonResponse?.errorType) {
        responseError.type = jsonResponse.errorType;
    }

    // If data sent include this as options
    if (jsonResponse?.data) {
        responseError.data = jsonResponse.data;
    }
    throw responseError;
};

// Now remake call (this time using new token) - any 401 is a genuine exception
const retryRequest = async ({ url, method, idToken, body, mimeType }) => {
    const headers = new Headers();
    headers.append('Authorization', idToken);
    if (mimeType) {
        headers.append('Content-Type', mimeType);
    } else {
        headers.append('Content-Type', 'application/json');
    }
    console.log(`Calling Re-try API: ${url}`);
    const retryResponse = await fetch(url, { method, headers, body, mimeType });

    if (retryResponse.status === 204) {
        return { noChange: true };
    }
    if (!retryResponse.ok) {
        const message = `An error has occured: ${retryResponse.status}`;
        throw new Error(message);
    }

    const jsonResponse = await retryResponse.json();
    return evaluateResponse({ success: true, jsonResponse });
};

// In the case of a 401 - re-login using refresh token
const retryLogin = async ({ url, method, body, mimeType }) => {
    // Does a refresh token exist?
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
        throw new Error('NotAuthorizedException');
    }
    const loginUrl = `${DOMAIN}auth/login?refreshToken=${refreshToken}`;
    console.log(`Calling: ${loginUrl}`);
    const authResponse = await fetch(loginUrl, { headers: jsonHeader(false) });
    console.log(`Response Status: ${authResponse.status}`);

    if (authResponse.status !== 200) {
        throw new Error('NotAuthorizedException');
    }

    // Worked => save data
    const jsonAuthResponse = await authResponse.json();
    console.log(jsonAuthResponse);
    // Check for failure
    if (!jsonAuthResponse.success || !jsonAuthResponse.data.idToken) {
        throw new Error('NotAuthorizedException');
    }
    const idToken = jsonAuthResponse.data.idToken;
    localStorage.setItem('idToken', idToken);

    // Now recall original API with new token
    return retryRequest({ url, method, idToken, body, mimeType });
};

// Wrapper for Fetch - includes handling 401 returns
const apiFetch = async ({ url, method = 'GET', auth = true, body, mimeType }, signal) => {
    console.log(`Calling: ${url}`);
    let response;

    if (signal) {
        response = await fetch(url, { method, headers: jsonHeader({ auth, mimeType }), body, signal });
    } else {
        response = await fetch(url, { method, headers: jsonHeader({ auth, mimeType }), body });
    }
    console.log(`Response Status: ${response.status}`);

    // Check for no change
    if (response.status === 204) {
        return { noChange: true };
    }
    // check for expired token
    if (response.status === 401) {
        // Retry login
        return retryLogin({ url, method, body, mimeType });
    }

    // Check for timeout or system resource issue
    if (response.status === 503) {
        const errorText =
            'We apologize but your request too too long to process. Please try again, maybe with less data. If this error persists please contact RightsClick Support.';
        const responseError = new Error(errorText);
        throw responseError;
    }

    // Check body
    console.log('Parsing JSON');
    const jsonResponse = await response.json();
    console.log(jsonResponse);

    return evaluateResponse({ success: response.ok, jsonResponse });
};

export { apiFetch, jsonHeader };
